import React from 'react'

const Bulletin = () => {
  return (
    <div>
        <div className='italic bg-new-green text-2xl font-medium p-5 py-12 border-orange-900 shadow-2xl rounded-xl'>
            <p>Just tap on the card</p>
            <p>which you want to visit</p>
            <p>and get your notes</p>
        {/* Abhi isme navigation se milta color lagana hai */}
        </div>

    </div>
  )
}

export default Bulletin